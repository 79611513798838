import { Text, Html, ContactShadows, PresentationControls, Float, Environment, useGLTF, Sparkles} from '@react-three/drei'

export default function Experience()
{
    const computer = useGLTF('https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/macbook/model.gltf')
    const magnent = useGLTF('https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/trophy-base/model.gltf')
    const mug = useGLTF('https://market-assets.fra1.cdn.digitaloceanspaces.com/market-assets/models/mug/model.gltf')
    const spaceship = useGLTF('https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/low-poly-spaceship/model.gltf')
    const coin = useGLTF('https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/coin/model.gltf')
    const table = useGLTF('./LowPolyServerRoom.gltf')
    const goDown = 1.2
    return <>

        <color args={ [ '#203a52' ] } attach="background" />

        <Environment preset="city" />
        
        <PresentationControls
            global
            rotation={ [ -0.2, -0.6, -0.1 ] }
            polar={ [ 0, 0 ] }
            azimuth={ [ - 1, 0.75 ] }
            config={ { mass: 2, tension: 400 } }
            snap={ { mass: 4, tension: 400 } }
            zoom={5}
        >
            // <Float rotationIntensity={ 0.2 } >  
                <rectAreaLight
                    width={ 2.5 }
                    height={ 1.65 }
                    intensity={ 65 }
                    color={ '#ff6900' }
                    rotation={ [ - 0.1, Math.PI, 0 ] }
                    position={ [ 0, 0.55, - 1.15 ] }
                />

                <object3D position={[0, 1, 0]}>
                    <Sparkles count={50} scale={[8, 3, 8]} color={"#ffaacc"} size={6} speed={0.2} noise={0.1}/>
                </object3D>
                <primitive
                    object={ table.scene }
                    position-y={ -2.4 - goDown}
                    position-z={ 9 - goDown}
                    position-x={ 0.6 - goDown}
                    scale={3,3,3}
                    // rotation-x={ 0.13 }
                ></primitive>
                <primitive
                    object={ spaceship.scene }
                    position={ [ -2.5, 0.83 - goDown, 0 ] }
                    scale={0.3,0.3,0.3}
                    // rotation={0,0,0}
                    rotation-y={ 0.5 }
                ></primitive>
                <primitive
                    object={ computer.scene }
                    position-y={ 0.1 - goDown }
                    position-z={ 2.3 - goDown }
                    position-x={ 1 - goDown }
                    scale={1,1,1}
                    // rotation-x={ 0.13 }
                >
                    <Html
                        transform
                        wrapperClass="htmlScreen"
                        distanceFactor={ 1.17 }
                        position={ [ 0, 1.56, - 1.4 ] }
                        rotation-x={ - 0.256 }
                    >
                        <iframe src="https://www.godotrts.com" />
                    </Html>
                </primitive>

                <Text
                    font="./bangers-v20-latin-regular.woff"
                    fontSize={ 1 }
                    position={ [ 4, 0.75, -2.75 ] }
                    rotation-y={ - 0.5 }
                    maxWidth={ 2 }
                >
                    Aviv David
                </Text>
            </Float>
        </PresentationControls>

        <ContactShadows
            position-y={ - 1.4 }
            opacity={ 0.4 }
            scale={ 5 }
            blur={ 2.4 }
        />

    </>
}